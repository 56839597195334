import {
	Document,
	Packer,
	Paragraph,
	HeadingLevel,
	Table,
	TableCell,
	TableRow,
	WidthType,
	LevelFormat,
	AlignmentType,
	convertInchesToTwip,
	PageOrientation,
	ShadingType,
	BorderStyle
} from "docx";
import { saveAs } from "file-saver";
import { createPopper } from '@popperjs/core';
//import * as fs from "fs";
import * as showdown from 'showdown';
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/js/bootstrap.js'
import Modal from 'bootstrap/js/dist/modal.js';
import 'bootstrap/dist/js/bootstrap.min.js'

// import '/src/docxtemplater.js'

window.$ = window.jQuery = require('jquery');
window.Popper = require('popper.js').default;
require('bootstrap');


$(document).ready(function () {

	// jQuery('#bd-example-modal-lg').modal('show');

	let url = new URL(window.location.href );
	let params = new URLSearchParams(url.search);
	let loadedForm;

	if( !params.has('nav') ){
		$('.sa-banner, .container#logo').hide();
	}

	//-- Set the date if none is set.
	if( $('[name="Date_of_Assessment"]').val() === ''){
		var currentdate = new Date();
		$('[name="Date_of_Assessment"]').val( String(currentdate.getDate()).padStart(2,'0') + "/" + String(currentdate.getMonth()+1).padStart(2,'0')  + "/" +  currentdate.getFullYear() );
	}

	//-- Setting file parser
	const reader = new FileReader();
	reader.addEventListener('load', (event) => {
		try{
			var file_content = event.target.result;

			const properties = JSON.parse(file_content);

			var Name_of_Assessor = false;
			var Name_of_Organisation = false;
			var Date_of_Assessment = false;
			var IMPACT_content_version = false;
			var IMPACT_mode = false;
			var formData = false;

			properties.forEach(async ( property ) => {

				if( property.name === 'Name_of_Assessor' ){
					Name_of_Assessor = property.value;
					$('input[name="Name_of_Assessor"]').val( Name_of_Assessor )
				}

				if( property.name === 'Name_of_Organisation' ){
					Name_of_Organisation = property.value;
					$('input[name="Name_of_Organisation"]').val( Name_of_Organisation )
				}

				if( property.name === 'Name_of_AssDate_of_Assessmentessor' ){
					Date_of_Assessment = property.value;
					$('input[name="Date_of_Assessment"]').val( Date_of_Assessment );
				}

				if( property.name === 'IMPACT_mode' ){
					IMPACT_mode = property.value;
				}

				if( property.name === 'IMPACT_content_version' ){
					IMPACT_content_version = property.value;
				}

				if( property.name === 'formData' ){
					formData = property.value;
				}

			});

			if( IMPACT_content_version !== $('input#IMPACT_content_version').val() ){
				alert('IMPACT content version mismatch [' + IMPACT_content_version + '] -> [' + $('input#IMPACT_content_version').val() + '], The form will continue to load but some of the content may have changed.');
			}


		window.drawForm( IMPACT_mode, formData );
		//-- Trigger loading..

		}
		catch(err) {
			console.log(err.message);
			alert('Failed to parse save file');
		}
	});

	// Hanndling the upload box
	$("#loadDoc").change(function(){
		try {
			if ( $(this).prop('files')[0].type === 'application/json' ){
				var file = $(this).prop('files')[0];
				reader.readAsText(file);
			}else{
				alert('Unsupported import file');
			}
		}
		catch(err) {
			console.log(err.message);
			alert('Unsupported file.');
		}
	});


	//-- Pull the into text
	$.getJSON( "Task.json", function (data) {
		var into = new showdown.Converter();
		$.each(data, function (key, val) {
			if( val['Summary'] === 'IMP Web Tool Intro' ){
				$('#into').html(into.makeHtml( val['Custom field (Functional Requirement Statement)'].replaceAll("]|(ht", "](ht") ));
			}
		});
	});

	// do whatever you want here
	window.drawForm = function (mode, formData = false) {

		//-- Disable security minded checkbox
		$('input[name="Security_minded"]').attr("disabled", true)

		//-- Working out of the user is Security Minded
		var securityMinded = ( $('input[name="Security_minded"]').is(':checked') ? true : false );

		//-- Clear any existing forms
		$('div#form').empty();

		var icons = {
			/*
									Assure: "Assure.png",
									Deliver: "Deliver.png",
									Exploit: "Exploit.png",
									Present: "Present.png",
									Procure: "Procure.png",
									Secure: "Secure.png",
									Specify: "Specify.png"
									*/
								};

		//-- Updateing mode text box
		$('input#IMPACT_mode').val( (mode == 'module' ? 'Module' : 'Activity') )

		//-- Draw body
		$.getJSON((mode == 'module' ? 'Module' : 'Activity') + ".json", function (data) {

			$('input#mode').val( (mode == 'module' ? 'Module' : 'Activity') );

			loadedForm = data;

			var i = 0;
			var ii = 0;
			$.each(data, function (key, val) {
				ii = 0;
				i++;

				var items = [];
				var converter = new showdown.Converter();

				items.push( '<span style="display: flex;"><h5 name="table_heading" class="my-auto d-none " style="float: left;">' + val['Order'] + `: ` + val['summary'] + `</h5></span>
								<p class="col" name="table_summary">` + ( true ? '' : val["summary"] ) + `</p>
								<div formID="` + val["id"] + `" class="fixed">
									<div class="row heading py-2 font-weight-bolde">
										<div class="col-4 h5">` + val['summary'] + `</div>			
										<div class="col-2 h5 text-center">` + ( mode == 'module' ? 'Assessment Required' : '' ) + `</div>
										<div class="col-3 h5 text-center hidden-sm-xs">Assessment Feedback</div>
										<div class="col-3 h5 text-center hidden-sm-xs">Actions</div>
									</div>
								</div>`);

				items.push(`
					<div class="row" formID="` + val["id"] + `" moss="` + val['mos'] + `" security="` + val['security'] + `">
						<div class="col-12 col-lg-4" name="table_statement">` + val['feild'] + `</div>
						<div class="col-2  pt-3 text-center hidden-sm-xs">
							<div class="form-switch form-switch-lg ` + ( mode != 'module' ? 'd-none' : '' ) + `">
							<input class="form-check-input this_one" childen="` + i + `" name="table_required" type="checkbox" name="required" data-toggle="tooltip" data-placement="bottom" title="is required?">
							</div>

						</div>
						<div class="col-3 text-center p-2 hidden-sm-xs">
							<textarea name="table_comments" style="height: 100%;width: 100%;"></textarea>
						</div>
						<div class="col-3 text-center p-2 hidden-sm-xs">
							<textarea name="table_comments2" style="height: 100%;width: 100%;"></textarea>
						</div>
					</div>`);

						if ( Array.isArray(val['items']) ){

							$.each(val['items'], function (itemKey, itemVal) {
								ii++;
								items.push(`
								<div formID="` + itemVal["summary"] + `" class="heading">
									<div class="row heading py-2 font-weight-bolde" number="` + i + `" style="background: #0883d5; display: none;">
										<div class="col-12 col-lg-4 h5">` + itemVal['summary'] + `</div>

										` + ( mode == 'module' ? '<div class="col-1 h5 hidden-sm-xs text-center">Required</div>' : '' ) + `
										<div class="col-` + ( mode == 'module' ? '1' : '2' ) + ` h5 text-center hidden-sm-xs">` + ( mode == 'module' ? 'Existing capability' : 'Activity in place?' ) + `</div>
										
										<div class="col-3 h5 text-center hidden-sm-xs">Assessment Feedback</div>
										<div class="col-3 h5 text-center hidden-sm-xs">Actions</div>
									</div>

									<div class="row mt-3 mb-4 hidden-sm-xs" number="` + i + `" style="display: none; ">
									<div class="col-12 hidden-sm-xs" name="table_statement">` + itemVal['feild'] + `</div>
								</div>
								`);

								$.each(itemVal['items'], function (itemKey2, itemVal2) {

									if ( itemVal2['summary'].split(":")[1] != 'z' ){
										items.push(`
										<div class="row" number="` + i + `" formID="` + itemVal2["summary"] + `" style="display: none; " moss="` + itemVal2['mos'] + `" security="` + itemVal2['security'] + `"  >
											<div class="col-12 col-lg-4" name="table_statement">` + itemVal2['summary'] + ` ` + itemVal2['feild'] + `</div>
											` + ( mode == 'module' ? `
												<div class="col-1 pt-3 text-center hidden-sm-xs">
													<input name="required" type="checkbox" data-toggle="toggle" data-on="Yes" data-off="No" data-onstyle="primary" data-offstyle="light">
												</div>` : '' ) + `
												<div class="col-` + ( mode == 'module' ? '1' : '2' ) + ` pt-3 text-center hidden-sm-xs">
													<input name="existingCapability" type="checkbox" data-toggle="toggle" data-on="Yes" data-off="No" data-onstyle="primary" data-offstyle="light">
												</div>
												<div class="col-3 text-center p-2 hidden-sm-xs">
													<textarea name="table_comments" style="height: 100%;width: 100%;"></textarea>
												</div>
												<div class="col-3 text-center p-2 hidden-sm-xs">
													<textarea name="table_comments2" style="height: 100%;width: 100%;"></textarea>
												</div>
										</div>
										`);
									}else{
										items.push(`
										<div class="row mb-3" number="` + i + `" formID="` + itemVal2["summary"] + `" style="display: none; ">
											<div class="col-12" name="table_statement">` + itemVal2['feild'] + `</div>
										</div>
										`);
									}
								});
							});
						}

				$("<div/>", {
					"id": key,
					"class": "functional-requirements",
					html: items.join("")
				}).appendTo("body #form");
			});

			//-- Uncheck required checkboxs
			$('[title="is required?"]').prop( "checked", false )

			$('.this_one').click(function() {
				$(this).closest('.functional-requirements').parent().find( '[number="' + $(this).attr('childen') + '"]' ).slideToggle();
			});

			//-- Open must
			$(  ( securityMinded ? '[moss="Must"] [name="required"][type="checkbox"], [security="Should to Must"] [name="required"][type="checkbox"]' :'[moss="Must"] [name="required"][type="checkbox"]' ) ).each(function(){
				$(this).trigger( "click" ).attr("disabled", true);
			});

			$(  ( securityMinded ? '[moss="Must"] [name="table_required"][type="checkbox"], [security="Should to Must"] [name="table_required"][type="checkbox"]' :'[moss="Must"] [name="table_required"][type="checkbox"]' ) ).each(function(){
				$(this).trigger( "click" ).attr("disabled", true);
			});
		});

		// Shpw form mow its been rended..
		$("#form").parent().removeClass('d-none');
		$('#block-helpfulness-helpfulness').addClass('d-none');
		//		$('#cover,#into').addClass('d-none');
		$('#into').addClass('d-none');

		//-- Adding Mode into the Bread crums
		$('.bredcrumb > ul').append('<li id="mode">' + ($(this).attr('mode') === 'activity' ? 'Activity' : 'Module' ) + '</li>');

		//-- IF we have formData
		if(formData){
			formData = JSON.parse(formData);
				//-- Add delay to allow the form load animation to finish
				setTimeout(function(){
					//-- Load childen
					formData.forEach((parent) => {
						// console.log(parent.id);
						$('div.row[formid="' + parent.id + '"]  textarea[name="table_comments"]').val( parent.table_comments );
						$('div.row[formid="' + parent.id + '"]  textarea[name="table_comments2"]').val( parent.table_comments2 );
						// console.log(parent.items);

						//-- Loading childen
						parent.items.forEach(( childen ) => {
							$('div.row[formid="' + childen.id + '"]  textarea[name="table_comments"]').val( childen.table_comments );
							$('div.row[formid="' + childen.id + '"]  textarea[name="table_comments2"]').val( childen.table_comments2 );
						});
					});
					// alert('Loaded fromData');
				}, 100);
		}
	}

	function saveDocumentToFile(doc, fileName) {
		// Create new instance of Packer for the docx module

		// Create a mime type that will associate the new file with Microsoft Word
		const mimeType =
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document";
		// Create a Blob containing the Document instance and the mimeType
		Packer.toBlob(doc).then((blob) => {
			const docblob = blob.slice(0, blob.size, mimeType);
			// Save the file using saveAs from the file-saver package
			saveAs(docblob, fileName);
		});
	}

	function feildRequired(required) {
		if( !required ){
			return (
				{
					//					fill: "A9A9A9",
					type: ShadingType.REVERSE_DIAGONAL_STRIPE,
					color: "auto",
				}
			)
		}else{
 		return(
				{
					// fill: "b79c2f",
					//type: ShadingType.REVERSE_DIAGONAL_STRIPE,
					//color: "auto",
				}
			);
		}
	}

	function parseHTML(table_statement) {

		const table_statement_clean = [];

		table_statement.children().each(function (index) {

			if ($(this).is("h1")) {
				table_statement_clean.push(

					new Paragraph({
						text: $(this).text(),
						heading: HeadingLevel.HEADING_2,
						spacing: {
							before: 100,
							after: 50,
						},
					}),
				);
			}
			if ($(this).is("p")) {
				table_statement_clean.push(
					new Paragraph({
						text: $(this).text(),
					}),
				);
			}
			if ($(this).is("ul")) {
				$(this).find('li').each(function (index2) {
					table_statement_clean.push(
						new Paragraph({
							text: $(this).text(),
							bullet: {
								level: 0 // How deep you want the bullet to be. Maximum level is 9
						}
						}));
				});
			}
		});
		return (table_statement_clean);
	}

		function generateWordDocument() {

			var sections = [];
			var i = 1;
			var table_statement_clean = [];

			var Name_of_Assessor = $('[name="Name_of_Assessor"]').val();
			var Name_of_Organisation = $('[name="Name_of_Organisation"]').val();
			var Date_of_Assessment = $('[name="Date_of_Assessment"]').val();
			var IMPACT_content_version = $('[name="IMPACT_content_version"]').val();
			var IMPACT_mode = $('input#mode').val();
			// Titel page

			sections.push({
				properties: {
					page: {
						size: {
							orientation: PageOrientation.LANDSCAPE,
						},
					},
				},
				children: [
					new Paragraph({
						text: 'Information Management Platform Assessment Checklist Tool IMPACT',
						heading: HeadingLevel.TITLE,
					}),

					...parseHTML( $('#into') ),

					new Paragraph({
						text: 'Name of Assessor',
						heading: HeadingLevel.HEADING_3,
						spacing: {
							before: 100,
							after: 50,
						},
					}),
					new Paragraph({
						text: ( Name_of_Assessor !== '' ? Name_of_Assessor : '-- None Set --' ),
					}),

					new Paragraph({
						text: 'Name of Organisation',
						heading: HeadingLevel.HEADING_3,
						spacing: {
							before: 100,
							after: 50,
						},
					}),
					new Paragraph({
						text: ( Name_of_Organisation !== '' ? Name_of_Organisation : '-- None Set --' ),
					}),

					new Paragraph({
						text: 'Date of Assessment',
						heading: HeadingLevel.HEADING_3,
						spacing: {
							before: 100,
							after: 50,
						},
					}),
					new Paragraph({
						text: ( Date_of_Assessment !== '' ? Date_of_Assessment : '-- None Set --' ),
					}),

					new Paragraph({
						text: 'IMPACT content version',
						heading: HeadingLevel.HEADING_3,
						spacing: {
							before: 100,
							after: 50,
						},
					}),
					new Paragraph({
						text: ( IMPACT_content_version !== '' ? IMPACT_content_version : '-- None Set --' ),
					}),
				]
			});

			var table_row = [];
			var formData = [];

			//-- Buliding aray of form data
			$('div [formid].heading, [formid].row').each(function () {

				// console.log( $('div#form').children('div.functional-requirements') );

				if ( $(this).is('[number]') ) {
					//-- Child element.
					formData[formData.length  - 1]['items'].push({  id : $(this).attr('formid'),
						Required: true,
						Already_provided: true,
						Assessment_Feedback: $(this).find('[name="table_comments"]').val(),
						Actions: $(this).find('[name="table_comments2"]').val()
					});
				}else{
					// Parent element
					formData.push({ id : $(this).attr('formid'),
													Assessment_Required : true,
													Assessment_Feedback: $(this).find('[name="table_comments"]').val(),
													Actions: $(this).find('[name="table_comments2"]').val(),
													value: 'Test123', "items": [] }
													);
				}

			});

			var fill = "";
			var requiredRow = true;
			var requiredUnit = true;

			$('#form .functional-requirements').each(function () {

				table_row = [];
				requiredRow = true;

				$(this).find('div[formid]').each(function () {
					fill = "";

					// Main heading
					if( $(this).hasClass( "fixed" ) ){
						fill = "076eb2";
						requiredRow = $(this).next('.row').find('input[type="checkbox"][name="table_required"]').is(':checked');
						requiredUnit = $(this).next('.row').find('input[type="checkbox"][name="table_required"]').is(':checked');

						table_row.push(
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 4505,
											type: WidthType.DXA,
										},
										children: [new Paragraph( $(this).find('.col-4').html() ) ],
										shading: { fill: fill, color: "ffffff",},
									}),
									
									new TableCell({
										width: {
											size: 250,
											type: WidthType.DXA,
										},
										children: [new Paragraph( ( IMPACT_mode == 'Module' ? 'Assessment Required' : '' ) ) ],
										columnSpan: 2,
										shading: { fill: fill, color: "ffffff",},
									}),
									
									new TableCell({
										width: {
											size: 250,
											type: WidthType.DXA,
										},
										children: [new Paragraph('Assessment Feedback') ],
										shading: { fill: fill, color: "ffffff",},
									}),

									new TableCell({
											width: {
												size: 3400,
												type: WidthType.DXA,
											},
											children: [new Paragraph('Actions') ],
											shading: { fill: fill, color: "ffffff",},
									}),
								]
							}),
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 4505,
											type: WidthType.DXA,
										},
										children: [new Paragraph( $(this).next('.row').find('[name="table_statement"]').html() ) ],
										shading: feildRequired( requiredRow )
									}),

									//-- if
									new TableCell({
										width: {
											size: 250,
											type: WidthType.DXA,
										},
										children: [new Paragraph(  ( IMPACT_mode == 'Module' ? ( requiredRow ? 'Required' : 'Not Required') : '' )  ) ],
										columnSpan: 2,
										shading: feildRequired( requiredRow )
									}),


									new TableCell({
										width: {
											size: 250,
											type: WidthType.DXA,
										},
										children: [new Paragraph ( $(this).next('.row').find('[name="table_comments"]').val() === ''? ' -- N/A --' : $(this).next('.row').find('[name="table_comments"]').val()  ) ],
										shading: feildRequired( requiredRow )
									}),
									new TableCell({
										width: {
											size: 3400,
											type: WidthType.DXA,
										},
										children: [new Paragraph ( $(this).next('.row').find('[name="table_comments2"]').val() === ''? ' -- N/A --' : $(this).next('.row').find('[name="table_comments2"]').val()  ) ],
										shading: feildRequired( requiredRow )
										}),
								],
							}),
						);
					}else if ( $(this).hasClass( "heading") ){
						// console.log( 'Sub heading' );

						fill = "0883d5";

						table_row.push(
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 6757,
											type: WidthType.DXA,
										},
										children: [new Paragraph( $(this).attr('formid') ) ],
										shading: { fill: fill, color: "ffffff",},
									}),
									new TableCell({
										width: {
											size: 250,
											type: WidthType.DXA,
										},
										children: [new Paragraph(( IMPACT_mode == 'Module' ? 'Required' : '') ) ],
										shading: { fill: fill, color: "ffffff",},
									}),
									new TableCell({
										width: {
											size: 250,
											type: WidthType.DXA,
										},
										children: [new Paragraph(( IMPACT_mode == 'Module' ? 'Existing capability' : 'Activity in place?') )],
										shading: { fill: fill, color: "ffffff",},
									}),
									new TableCell({
										width: {
											size: 3400,
											type: WidthType.DXA,
										},
										children: [new Paragraph('Assessment Feedback') ],
										shading: { fill: fill, color: "ffffff",},
									}),
									new TableCell({
											width: {
												size: 3400,
												type: WidthType.DXA,
											},
											children: [new Paragraph('Actions') ],
											shading: { fill: fill, color: "ffffff",},
									}),
								]
							}),
						);

					}else if ( $(this).hasClass( "row" ) && $(this).is('[number]') ){

						if(  $(this).hasClass("mb-3") ){
								table_row.push(
									new TableRow({
										children: [
											new TableCell({
												width: {
													size: 4505,
													type: WidthType.DXA,
												},
												children: [new Paragraph( $(this).find('[name="table_statement"]').html() ) ],
												columnSpan: 5,
												shading: feildRequired( requiredRow )
											})
										]
									}),
								);
						}else{
							requiredRow = $(this).find('input[type="checkbox"][name="required"]').is(':checked');

							table_row.push(
								new TableRow({
									children: [
										new TableCell({
											width: {
												size: 4505,
												type: WidthType.DXA,
											},
											children: [new Paragraph( $(this).find('[name="table_statement"]').html() ) ],
											shading: feildRequired( requiredRow )
										}),
										new TableCell({
											width: {
												size: 250,
												type: WidthType.DXA,
											},
											children: [new Paragraph( ( IMPACT_mode == 'Module' ? ( requiredRow ? 'Yes' : 'No') : '' ) ) ],
											shading: feildRequired( requiredRow )
										}),
										new TableCell({
											width: {
												size: 250,
												type: WidthType.DXA,
											},
											children: [new Paragraph( ( $(this).find('input[type="checkbox"][name="existingCapability"]').is(':checked') ? 'Yes' : 'No') ) ],
											shading: feildRequired( requiredRow )
										}),
										new TableCell({
											width: {
												size: 4505,
												type: WidthType.DXA,
											},
											children: [new Paragraph( ($(this).find('textarea[name="table_comments"]').val() === ''? ' -- N/A --' : $(this).find('textarea[name="table_comments"]').val() ) ) ],
											shading: feildRequired( requiredRow )
										}),
										new TableCell({
												width: {
													size: 4505,
													type: WidthType.DXA,
												},
												children: [new Paragraph( ($(this).find('textarea[name="table_comments2"]').val() === ''? ' -- N/A --' : $(this).find('textarea[name="table_comments"]').val() ) ) ],
												shading: feildRequired( requiredRow )
										}),
									]
								}),
							);
						}
					}
				});

				///-- Push Table/sections onto the page
				sections.push({
					properties: {
						page: {
							size: {
								orientation: PageOrientation.LANDSCAPE,
							},
						},
					},
					children: [
						new Table({
							columnWidths: [4505, 250, 250, 4505, 4505],
							rows: table_row,
						}),
					]
				});
			});


			var Properties = [
				{ name: "Name_of_Assessor", value: Name_of_Assessor },
				{ name: "Name_of_Organisation", value: Name_of_Organisation },
				{ name: "Date_of_Assessment", value: Date_of_Assessment },
				{ name: "IMPACT_content_version", value: IMPACT_content_version },
				{ name: "IMPACT_mode", value: IMPACT_mode },
				{ name: "formBase", value: JSON.stringify(loadedForm) },
				{ name: 'formData', value: JSON.stringify(formData) }
			];

		const doc = new Document({
			customProperties: Properties,
			numbering: {
				config: [{
					levels: [{
						level: 0,
						format: LevelFormat.DECIMAL,
						text: "%1.",
						alignment: AlignmentType.START,
						style: {
							paragraph: {
								indent: {
									left: convertInchesToTwip(0.5),
									hanging: convertInchesToTwip(0.18)
								},
							},
						},
					}, ],
					reference: "my-number-numbering-reference",
				}],
			},
			styles: {
				paragraphStyles: [{
					id: "wellSpaced",
					name: "Well Spaced",
					basedOn: "Normal",
					quickFormat: true,
					paragraph: {
						spacing: {
							line: 276,
							before: 20 * 72 * 0.1,
							after: 20 * 72 * 0.05
						},
					},
				}, ],
			},
			sections: sections,
		});

		var currentdate = new Date();

		// Call saveDocumentToFile with the document instance and a filename
		saveDocumentToFile(doc, "IMPACT_" + IMPACT_mode + "s-" + currentdate.getFullYear() + "-" + String(currentdate.getMonth()+1).padStart(2,'0') + "-" +	String(currentdate.getDate()).padStart(2,'0') + ".docx");

	  	// Saveing properties
		//let blob = new Blob([JSON.stringify(Properties)], { type: 'application/json' })
		// saveAs(blob, 'IMPACT_' + $('#mode').val() + "s-" + currentdate.getFullYear() + "-" + String(currentdate.getMonth()+1).padStart(2,'0') + "-" +	String(currentdate.getDate()).padStart(2,'0') + '.json')
	}

	function findBreakpoint() {
		var width = $(window).width();
		if (width < 576) {
			return 'xs';
		} else if (width < 768) {
			return 'sm';
		} else if (width < 992) {
			return 'md';
		} else if (width < 1200) {
			return 'lg';
		} else if (width < 1400) {
			return 'xl';
		} else {
			return 'xxl';
		}
	}

	// Listen for clicks on Generate Word Document button
	$("#generate").click(function () {
		generateWordDocument();
	});
	
	$(".show").click(function () {
		// activity
		window.drawForm($(this).attr('mode'));
		
		if(findBreakpoint() === 'md' || findBreakpoint() === 'sm' || findBreakpoint() === 'xs'){
			setTimeout(function () {
				$('.form-switch.form-switch-lg input.form-check-input:not(:checked)').each(function( index ) {
				   $( this ).click();
			   });
			}, 2000);
		}
	});


	$( window ).resize(function() {
		setTimeout(function () {
		$( "#warrningModal" ).modal('show');
			if(findBreakpoint() === 'md' || findBreakpoint() === 'sm' || findBreakpoint() === 'xs'){
				$('.form-switch.form-switch-lg input.form-check-input:not(:checked)').each(function( index ) {
				   $( this ).click();
			   });
			}
		}, 800);
	});

	//-- Makeing the close BTN works
	$('.close, [data-dismiss="modal"]').click(function( ){
		$( "#warrningModal" ).modal('hide');
	});

	if(findBreakpoint() === 'md' || findBreakpoint() === 'sm' || findBreakpoint() === 'xs'){
		$( "#warrningModal" ).modal('show');
	}

});